<template>
  <div class="auteurList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div class="tableElement">
      <table id="tableAuteur" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="auteur in paginatedAuteurs" :key="auteur.idAuteur" @click="toggleSelected(auteur)"
              :class="{ 'table-active': isSelected(auteur.idAuteur) }">
            <td class="text-center">
              <input type="checkbox" v-model="selectedAuteurs" :value="auteur.idAuteur" disabled>
            </td>
            <td class="text-center">{{ auteur.idAuteur }}</td>
            <td class="text-center">{{ auteur.nom }}</td>
            <td class="text-center">{{ auteur.prenom }}</td>
            <td class="text-center">{{ auteur.email }}</td>
            <td class="text-center">{{ auteur.organisme }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-row class="mt-4">
      <b-col cols="1" class="icon">
        <a class="icon" title="ajouter un nouvel auteur" @click.prevent="showModalAdd = !showModalAdd; showModalModif=false"
           align-self="center">
          <i class="fas fa-plus-circle" id="ajoutAuteur"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedAuteurs.length > 0" title="modifier l'auteur sélectionné"
           @click.prevent="showModalModif = !showModalModif; showModalAdd=false" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifAuteur"></i>
        </a>
        <a class="icon" v-else title="modifier l'auteur sélectionné" @click="selection" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifAuteur"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a v-if="selectedAuteurId !== null" class="icon" title="supprimer l'auteur sélectionné"
           @click="deleteAuteur" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteAuteur"></i>
        </a>
        <a v-else class="icon" title="supprimer l'auteur sélectionné" @click="selection" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteAuteur"></i>
        </a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-auteur" v-model="filtre_auteurs" type="search"
              placeholder="Rechercher Auteur">
            </b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_auteurs" @click="filtre_auteurs = ''">
                <i class="fa-solid fa-arrows-rotate"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{ nbResult }} auteurs(s) sur {{ totalRows }}</p>
        </div>
        <div class="pagination">
          <b-pagination
              class="customPagination"
              v-model="currentPage"
              :total-rows="nbResult !== 0 ? nbResult : filteredAuteurs.length"
              :per-page="perPage"
              aria-controls="my-card"
              size="sm"
              align="right"
              id="paginationAuteur">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div id="my-modal4" size="xl" v-if="showModalAdd">
      <ModalAdd @close="showModalAdd = false" @notification="handleNotification"></ModalAdd>
    </div>
    <div id="my-modal5" size="xl" v-if="showModalModif && lastSelectedAuteurId !== null">
      <ModalModif :id="lastSelectedAuteurId" @close="showModalModif = false" @notification="handleNotification"></ModalModif>
    </div>

    <div v-if="auteursApp.length!==0" class="auteursApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Auteurs de l'application séléctionnée:</u></strong></p>
        <AuteurApp
            :auteursApp="auteursApp"
            @deleteAuteur="handleDeleteAuteur">
        </AuteurApp>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" @click="insertAuteursFromApp(auteursApp)" class="btn-ajout" id="addAuteursApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>
    </div>

  </div>
</template>

<script>
import {defineComponent, ref} from "vue";
import ModalAdd from "@/components/Applications/Auteur/ModalAjouterAuteur.vue";
import ModalModif from "@/components/Applications/Auteur/ModalModifierAuteur.vue";
import AuteurApp from "@/components/Applications/Auteur/AuteurListFrom.vue"
import useAuteursList from "@/composition/Auteurs/auteursList";
import NotificationMessage from "@/components/Notification.vue";
import eventBus from "@/eventBus";

export default defineComponent({
  name: "AuteursList",
  components: {
    NotificationMessage,
    ModalAdd,
    ModalModif,
    AuteurApp
  },

  props: {
    auteurs: {
      type: Array,
      required: true,
    },
    lectureSeule: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const auteursList = useAuteursList(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner un auteur";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...auteursList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification,
    };
  },
});
</script>
<style scoped>

.auteursApp{
 text-align: center;
  justify-content: center;
}

</style>