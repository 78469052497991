<template>
  <transition name="modal">
    <b-card class="modal-container">
      <form @submit.prevent="emitModifierApplication" id="modifDonnees">
        <div class="titreModal pt-2 pb-2">
          <h5>Modifier l'application sélectionnée</h5>
        </div>
        <div>
          <b-card class=" formModal mr-4 ml-4">
              <b-form-group class="labelTitre" id="input-group-1" label="Titre*:" label-size="sm" label-for="input-1">
                <Field name="titre" type="text" id="input1" v-model="titre" placeholder="renseigner le titre de l'application" class="form-control" />
                <ErrorMessage name="titre" class="text-danger" />
              </b-form-group>
              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" id="input-group-2" label="Année*:" label-size="sm" label-for="input-2">
                    <Field name="annee" type="text" v-model="annee" class="form-control" />
                    <ErrorMessage name="annee" class="text-danger" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="labelTitre"  label="Détail méthode:" label-size="sm" label-for="checkbox-1">
                <b-form-checkbox id="checkbox-1" v-model="detailMethode">
                </b-form-checkbox>
              </b-form-group>
              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" id="input-group-3" label="N° Etude Donesol:" label-size="sm" label-for="input-3">
                    <b-form-input id="input-3" v-model="noEtude" type="text" size="sm">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="labelTitre" id="input-group-4" label="Résolution:" label-size="sm" label-for="input-4">
                <b-form-select id="input-4" size="sm" v-model="resolution.code">
                  <option v-for="r in resolutions" v-bind:value="r.code" v-bind:key="r.code">{{r.signification}}</option>
                </b-form-select>
              </b-form-group>
              <b-form-group class="labelTitre" id="input-group-5" label="Etendue:" label-size="sm" label-for="input-6">
                <b-form-select id="input-4" size="sm" v-model="etendue.code">
                  <option v-for="e in etendues" v-bind:value="e.code" v-bind:key="e.code">{{e.signification}}</option>
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col cols="6">
                  <b-form-group class="labelTitre" id="input-group-7" label="Nature de l'étendue:" label-size="sm" label-for="input-7">
                    <b-form-input id="input-7" v-model="surface" type="text" size="sm">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group class="labelTitre" id="input-group-8" label="Finalité:" label-size="sm" label-for="input-8">
                <b-form-select id="input-8" size="sm" v-model="finalite.code">
                  <option v-for="f in finalites" v-bind:value="f.code" v-bind:key="f.code">{{f.signification}}</option>
                </b-form-select>
              </b-form-group>
              <b-form-group class="labelTitre"  label="Application validée:" label-size="sm" label-for="checkbox-2">
                <b-form-checkbox id="checkbox-2" v-model="validee">
                </b-form-checkbox>
              </b-form-group>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
        </div>
      </form>

    </b-card>
  </transition>
</template>

<script>
  import useModifApplication from '@/composition/Applications/modifApplication';
  import { Field, ErrorMessage} from 'vee-validate';


  export default {
    name: 'ModifApplication',
    components: {
      Field,
      ErrorMessage
    },
    props:{
      id:{
        type:Number,
        required: true
      },
    },

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setup(props, { emit }) {
     return useModifApplication(props, { emit });
    }
  };
</script>

<style>

</style>