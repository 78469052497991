import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http.js';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import eventBus from '@/eventBus.js';
import store from "@/store";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAjoutApplication({ emit }) {
    const locale = ref('fr-FR');
    const idApplication = ref('');
    const annee = ref('');
    const titre = ref('');
    const detailMethode = ref(false);
    const noEtude = ref('');
    const finalites = ref([]);
    const finalite = ref({});
    const resolutions = ref([]);
    const resolution = ref({});
    const surface = ref('');
    const validee = ref(false);
    const etendues = ref([]);
    const etendue = ref({});
    const invalid = ref(false);
    const applicationData = ref({});

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        titre: yup.string().required('Ce champ est obligatoire'),
        annee: yup.string().required('Ce champ est obligatoire')
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const fetchEtendues = async () => {
        etendues.value = await http.getEtendues();
    };

    const fetchFinalites = async () => {
        finalites.value = await http.getFinalites();
    };

    const fetchResolutions = async () => {
        resolutions.value = await http.getResolutions();
    };

    onMounted(() => {
        fetchEtendues();
        fetchFinalites();
        fetchResolutions();

        const handleInsertApplication = handleSubmit(async (values) => {
            applicationData.value = {
                idApplication: idApplication.value,
                annee: values.annee,
                titre: values.titre,
                detailMethode: detailMethode.value,
                noEtude: noEtude.value,
                finalite: finalite.value,
                resolution: resolution.value,
                etendue: etendue.value,
                surface: surface.value,
                validee: validee.value,
                saisiPar: store.state.idUtilisateur
            };

            try {
                const data = await http.insertApplication(applicationData.value);
                idApplication.value = data;
                emit('close');
                eventBus.emit('reloadApplications');
                emit('notification',{
                    showNotification : true,
                    message : "Application ajoutée avec succès!",
                    type : "success",
                })

            } catch (error) {
                emit('notification',{
                    showNotification : true,
                    message : 'Erreur lors de l\'ajout de l\'application.',
                    type : "danger",
                })
            }
        });

        eventBus.on('insertApplication', handleInsertApplication);

        onBeforeUnmount(() => {
            eventBus.off('insertApplication', handleInsertApplication);
        });
    });

    const emitInsertApplication = () => {
        eventBus.emit('insertApplication');
    };

    return {
        locale,
        idApplication,
        annee,
        titre,
        detailMethode,
        noEtude,
        finalites,
        finalite,
        resolutions,
        resolution,
        surface,
        validee,
        etendues,
        etendue,
        invalid,
        applicationData,
        emitInsertApplication,
        message,
        showNotification,
        type,
        duration
    };
}