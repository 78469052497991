<template>
  <div class="thematiqueList">
    <div class="tableSecondElement">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="thematique in thematiquesApp" :key="thematique.idThematique">
            <td class="text-center">{{ thematique.idThematique }}</td>
            <td class="text-center">{{ thematique.groupe }}</td>
            <td class="text-center">{{ thematique.thematiquePrincipale }}</td>
            <td class="text-center">{{ thematique.sousThematique }}</td>
            <td class="text-center"><a @click="supprimerThematique(thematique)" class="icon_delete" title="supprimer la thematique"><i class="fa-solid fa-trash-can"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, toRefs } from 'vue';
export default defineComponent({
  name: "ThematiqueAppList",

  props:{
    thematiquesApp:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  setup(props, { emit }) {
    const fields = ref([
      {key: 'idThematique', label:'Id', class: 'text-center'},
      {key: 'groupe', label:'Groupe thématique', class: 'text-center'},
      {key: 'thematiquePrincipale', label:'Thématique', class: 'text-center'},
      {key: 'sousThematique', label:'Sous thématique', class: 'text-center'},
      { key: "actions", label: "Actions", class: "text-center" },
    ]);

    const supprimerThematique = (thematique) => {
      emit("deleteThematique", thematique);
    };

    return {
      ...toRefs(props),
      fields,
      supprimerThematique
    };
  }
});

</script>
<style scoped>

</style>