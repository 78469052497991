<template>
  <div class="refBiblioList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div class="tableElement">
      <table id="tableRef" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ref in paginatedRefBiblios" :key="ref.idRef" @click="toggleSelected(ref)"
              :class="{ 'table-active': isSelected(ref.idRef) }">
            <td class="text-center">
              <input type="checkbox" v-model="selectedRefBiblios" :value="ref.idRef" disabled>
            </td>
            <td class="text-center">{{ ref.idRef }}</td>
            <td class="text-center">{{ ref.titre }}</td>
            <td class="text-center">{{ ref.type }}</td>
            <td class="text-center">{{ ref.document }}</td>
            <td class="text-center">{{ ref.annee }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-row>
      <b-col cols="1" class="icon">
        <a class="icon" title="ajouter une nouvelle référence" @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center">
          <i class="fas fa-plus-circle" id="ajoutRef"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedRefBiblioId !== null" title="modifier la référence sélectionnée" @click.prevent ="showModalModif = !showModalModif; showModalAdd=false" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifRef"></i>
        </a>
        <a class="icon" v-else title="modifier la référence sélectionnée" @click="selection" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifRef"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedRefBiblioId !== null" title="supprimer la référence sélectionnée" v-on:click = "deleteRef" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteRef"></i>
        </a>
        <a class="icon" v-else title="supprimer la référence sélectionnée" @click = "selection" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteRef"></i>
        </a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-ref" v-model="filtre_refBiblios" type="search" placeholder="Rechercher Référence"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_refBiblios" @click="filtre_refBiblios = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} référence(s) sur {{ totalRows }}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : filteredRefBiblios.length"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationRef">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div id="my-modal10" size="xl" v-if="showModalAdd">
      <ModalAdd @close="showModalAdd = false" @notification="handleNotification"></ModalAdd>
    </div>
    <div id="my-modal11" size="xl" v-if="showModalModif&& lastSelectedRefBiblioId !== null">
      <ModalModif :id="lastSelectedRefBiblioId" @close="showModalModif = false" @notification="handleNotification"></ModalModif>
    </div>

    <div v-if="refsApp.length!==0" class="refsApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Références de l'application séléctionnée:</u></strong></p>
        <RefBiblioApp
            :refsApp="refsApp"
            @deleteRef="handleDeleteRef">
        </RefBiblioApp>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" v-on:click="insertRefBibliosFromApp(refsApp)" class="btn-ajout" id="addRefToApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>
    </div>

  </div>
</template>
<script>
import {defineComponent, ref} from "vue";
import { useRefBiblioList } from "@/composition/RefBiblio/refBiblioList"
import ModalAdd from "@/components/Applications/RefBiblio/ModalAjouterRef.vue"
import ModalModif from "@/components/Applications/RefBiblio/ModalModifierRef.vue"
import RefBiblioApp from "@/components/Applications/RefBiblio/RefBiblioAppList.vue"
import NotificationMessage from "@/components/Notification.vue";
import eventBus from "@/eventBus";

export default defineComponent({
  name: "RefBiblioList",

  components: {
    NotificationMessage,
    ModalAdd,
    ModalModif,
    RefBiblioApp,
  },

  props:{
    refsBiblios:{
      type:Array,
      required:true
    },
  },

  setup(props) {
    const refsBiblioList = useRefBiblioList(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner une référence";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...refsBiblioList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification
    };
  },
});
</script>
<style scoped>

.refsApp{
  text-align: center;
  justify-content: center;
}

</style>