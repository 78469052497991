import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from "@/http";
import eventBus from '@/eventBus.js';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useVoirApplication(props) {
    const titre = ref('');
    const annee = ref('');
    const detailMethode = ref(false);
    const noEtude = ref('');
    const finalites = ref([]);
    const finalite = ref('');
    const resolutions = ref([]);
    const resolution = ref('');
    const surface = ref('');
    const validee = ref('');
    const etendues = ref([]);
    const etendue = ref('');

    const updateModalData = (idApplication) => {
        http.loadApplication(idApplication).then((data) => {
            titre.value = data.titre;
            annee.value = data.annee;
            detailMethode.value = data.detailMethode;
            noEtude.value = data.noEtude;
            finalite.value = data.finalite;
            resolution.value = data.resolution;
            etendue.value = data.etendue;
            surface.value = data.surface;
            validee.value = data.validee;
        });
    };

    onMounted(() => {
        eventBus.on('updateApplicationSelected', updateModalData);

        http.getEtendues().then((data) => {
            etendues.value = data;
        });
        http.getFinalites().then((data) => {
            finalites.value = data;
        });
        http.getResolutions().then((data) => {
            resolutions.value = data;
        });

        if (props.id !== undefined) {
            http.loadApplication(props.id).then((data) => {
                titre.value = data.titre;
                annee.value = data.annee;
                detailMethode.value = data.detailMethode;
                noEtude.value = data.noEtude;
                finalite.value = data.finalite;
                resolution.value = data.resolution;
                etendue.value = data.etendue;
                surface.value = data.surface;
                validee.value = data.validee;
            });
        }
    });

    onBeforeUnmount(() => {
        eventBus.off('updateApplicationSelected', updateModalData);
    });

    return {
        titre,
        annee,
        detailMethode,
        noEtude,
        finalites,
        finalite,
        resolutions,
        resolution,
        surface,
        validee,
        etendues,
        etendue
    };
}