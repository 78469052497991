import {ref, computed, watchEffect, onMounted, onBeforeUnmount, watch} from "vue";
import http from "@/http";
import eventBus from "@/eventBus";
import applications from "@/views/Applications.vue";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useApplicationList(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectMode = ref("single");
    const showModalAdd = ref(false);
    const showModalModif = ref(false);
    const showModalAfficher = ref(false);
    const idApplication = ref(null);
    const filtre_applications = ref("");
    const selectedApplicationId = ref(null);
    const nbResult = ref(0);
    const totalRows = computed(() => props.applications.length);

    const fields = [
        { key: "selection", label: "Sélection", class: "text-center" },
        { key: "idApplication", label: "Id", class: "text-center" },
        { key: "titre", label: "Titre", class: "text-center" },
        { key: "annee", label: "Année", class: "text-center" },
        { key: "detailMethode", label: "Détail méthode", class: "text-center" },
        { key: "validee", label: "App.Validée", class: "text-center" },
    ];

    const filteredApplications = computed(() => {
        if (!filtre_applications.value) return props.applications;
        return props.applications.filter(
            (application) =>
                (application.titre &&
                    application.titre.toLowerCase().includes(filtre_applications.value.toLowerCase())) ||
                (application.annee && application.annee.toString().includes(filtre_applications.value))
        );
    });
    watch(selectedApplicationId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateApplicationSelected", newVal);
        }
    });
    watchEffect(() => {
        nbResult.value = filteredApplications.value.length;
        currentPage.value = 1;
    });

    const paginatedApplications = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredApplications.value.slice(start, end);
    });

    const selectApplication = (applicationId) => {
        selectedApplicationId.value = applicationId;
    };

    const deleteApp = async () => {
        if (confirm(`Voulez-vous vraiment supprimer l'application : ${selectedApplicationId.value}`)) {
            try {
                await http.deleteApplication(selectedApplicationId.value);
                selectedApplicationId.value = null;
                eventBus.emit("reloadApplications");
                eventBus.emit("notification", {
                    message: "Application supprimée avec succès",
                    type: "success"
                });
            } catch (error) {
                eventBus.emit("notification", {
                    message: "Erreur lors de la suppression de l'application",
                    type: "danger"
                });
            }
        }
    };

    const getApplications = () => {
        http.getApplications().then((data) => {
            applications.value = data;
        });
    };

    onMounted(() => {
        nbResult.value = 0;
        eventBus.on("reloadApplications", getApplications);

        onBeforeUnmount(() => {
            eventBus.off("reloadApplications", getApplications);
        });
    });

    return {
        perPage,
        currentPage,
        selectMode,
        showModalAdd,
        showModalModif,
        showModalAfficher,
        idApplication,
        filtre_applications,
        nbResult,
        fields,
        filteredApplications,
        paginatedApplications,
        selectedApplicationId,
        totalRows,
        deleteApp,
        selectApplication,
    };
}