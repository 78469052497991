<template>
  <div class="applications" id="accordionExample">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div class="accordion" role="tablist">
      <b-card no-body="" class="mb-1">
        <b-card-header header-tag="header" class="card p-1" role="tab">
          <b-button block @click="toggleAccordion" class="app">Applications thématiques</b-button>
        </b-card-header>
        <b-card-body id="accordion-1" v-show="accordionOpen">
          <ApplicationList :applications="applications" />
        </b-card-body>
      </b-card>

      <b-row>
        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1" id="localisation" class="app">Localisation de l'application </b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2" id="source" class="app">Personne(s) source </b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3"  id="thematique" class="app">Thématique(s)</b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4"  id="orgD" class="app">Organisme demandeur</b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5"  id="orgC" class="app">Organisme concepteur</b-button>
            </b-card-header>
          </b-card>
        </b-col>

        <b-col>
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="card p-1" role="tab">
              <b-button data-toggle="collapse" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6"  id="reference" class="app">Références bibliographiques</b-button>
            </b-card-header>
          </b-card>
        </b-col>
      </b-row>

      <div id="collapse1" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <b-card-body>
          <p class="titreOnglet">Localisation de l'application </p>
          <localisation  @notification="handleNotification"></localisation>
        </b-card-body>
      </div>
      <div id="collapse2" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <b-card-body>
          <p class="titreOnglet">Personne(s) source d'information sur l'application </p>
          <auteurs :auteurs="auteurs"></auteurs>
        </b-card-body>
      </div>
      <div id="collapse3" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <b-card-body>
          <p class="titreOnglet">Thématique(s) de l'application </p>
          <thematique :thematiques="thematiques"></thematique>
        </b-card-body>
      </div>
      <div id="collapse4" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <b-card-body>
          <p class="titreOnglet">Organisme demandeur de l'application </p>
          <orgD :organismes = "organismes"></orgD>
        </b-card-body>
      </div>
      <div id="collapse5" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <b-card-body>
          <p class="titreOnglet">Organisme concepteur de l'application </p>
          <orgC :organismes ="organismes"></orgC>
        </b-card-body>
      </div>
      <div id="collapse6" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
        <b-card-body>
          <p class="titreOnglet">Références bibliographiques </p>
          <ref-biblio-list :refs-biblios="refBiblios"></ref-biblio-list>
        </b-card-body>
      </div>
    </div>
  </div>
</template>

<script>

import { useApplications } from '@/composition/Applications/applications';
import ApplicationList from "@/components/Applications/Application/ApplicationList.vue";
import Localisation from "@/components/Applications/Localisation/LocalisationList.vue";
import auteurs from "@/components/Applications/Auteur/AuteurList.vue";
import orgC from "@/components/Applications/Organisme/ListOrganismeConcepteur.vue"
import orgD from "@/components/Applications/Organisme/ListOrganismeDemandeur.vue"
import thematique from "@/components/Applications/Thematique/ThematiqueList.vue"
import refBiblioList from "@/components/Applications/RefBiblio/RefBiblioList.vue";
import {onBeforeUnmount, onMounted, ref} from "vue";
import http from "@/http";
import eventBus from "@/eventBus";
import NotificationMessage from "@/components/Notification.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Applications",
  components: {
    NotificationMessage,
    Localisation,
    ApplicationList,
    auteurs,
    orgC,
    orgD,
    thematique,
    refBiblioList,

  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const {
      applications,
      applicationData,
      auteurs,
      auteurData,
      accordionOpen,
      toggleAccordion } = useApplications();

    const organismes = ref([]);
    const organismeData = ref({});

    const thematiques = ref([]);
    const thematiqueData = ref({});

    const refBiblios = ref([]);
    const refBiblioData = ref ({});

    const showModal = ref(false);

    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const getOrganismes = () => {
      http.getOrganismes().then((data) => {
        organismes.value = data;
      });
    };

    const getThematiques = () => {
      http.getThematiques().then((data) => {
        thematiques.value = data;
      });
    };

    const getRefBiblios = () => {
      http.getRefs().then((data) => {
        refBiblios.value = data;
      });
    };

    onMounted(() => {
      getOrganismes();
      const handleReloadOrganismes = () => {
        getOrganismes();
      };
      eventBus.on("reloadOrganismes", handleReloadOrganismes);
      onBeforeUnmount(() => {
        eventBus.off("reloadOrganismes", handleReloadOrganismes);
      });

      getThematiques();
      const handleReloadThematiques = () => {
        getThematiques();
      };
      eventBus.on("reloadThematiques", handleReloadThematiques);
      onBeforeUnmount(() => {
        eventBus.off("reloadThematiques", handleReloadThematiques);
      });

      getRefBiblios();
      const handleReloadRefs = () => {
        getRefBiblios();
      };
      eventBus.on("reloadRefs", handleReloadRefs);
      onBeforeUnmount(() => {
        eventBus.off("reloadRefs", handleReloadRefs);
      });
    });


    return {
      applications,
      applicationData,
      auteurs,
      auteurData,
      organismes,
      organismeData,
      thematiques,
      thematiqueData,
      refBiblios,
      refBiblioData,
      accordionOpen,
      toggleAccordion,
      getOrganismes,
      getThematiques,
      getRefBiblios,
      showNotification,
      notificationMessage,
      notificationType,
      handleNotification,
      showModal
    };
  },
};
</script>

<style>
#accordion-1 {
  background-color: aliceblue;
}
.applications {
  margin-left: 4em;
  margin-right: 4em;
  background-color: aliceblue;
}
.app {
  background-color: #66c1bf !important;
}
.titreOnglet {
  display: flex;
  font-size: 20px;
  justify-content: center;
  text-decoration: underline;
  font-weight: bold;
}
</style>