<template>
  <div class="thematiqueList">
    <div class="tableElement">
      <table id="tableThematique" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="thematique in paginatedThematiques" :key="thematique.idThematique" @click="toggleSelected(thematique)"
              :class="{ 'table-active': isSelected(thematique.idThematique) }">
            <td class="text-center">
              <input type="checkbox" v-model="selectedThematiques" :value="thematique.idThematique" disabled>
            </td>
            <td class="text-center">{{ thematique.idThematique }}</td>
            <td class="text-center">{{ thematique.groupe }}</td>
            <td class="text-center">{{ thematique.thematiquePrincipale }}</td>
            <td class="text-center">{{ thematique.sousThematique }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-row>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-thematique" v-model="filtre_thematiques" type="search" placeholder="Rechercher Thematique"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_thematiques" @click="filtre_thematiques = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} thématique(s) sur {{totalRows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : filteredThematiques.length"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationThem">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div v-if="thematiquesApp.length!==0" class="thematiquesApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Thématiques de l'application séléctionnée:</u></strong></p>
        <ThematiquesAppList
          :thematiques-app="thematiquesApp"
          @deleteThematique = "handleDeleteThematique">
        </ThematiquesAppList>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" v-on:click="insertThematiquesFromApp(thematiquesApp)" class="btn-ajout" id="addThemToApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>

    </div>

  </div>
</template>
<script>
import {defineComponent, ref} from "vue";
import ThematiquesAppList from "@/components/Applications/Thematique/ThematiquesAppList.vue";
import {useThematique} from "@/composition/Thematiques/listThematiques";
import eventBus from "@/eventBus";

export default defineComponent({
  name: "ThematiqueList",
components: {
    ThematiquesAppList,
},

  props:{
    thematiques:{
      type:Array,
      required:true
    },
  },

  setup(props) {
    const thematiqueList = useThematique(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...thematiqueList,
      showNotification,
      notificationMessage,
      notificationType,
      handleNotification
    };
  },
});
</script>
<style scoped>

.thematiquesApp{
  text-align: center;
  justify-content: center;
}

</style>