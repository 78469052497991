import { ref, onMounted, onBeforeUnmount } from "vue";
import http from "@/http";
import eventBus from "@/eventBus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useLocalisationList(emit) {
    const selected = ref([]);
    const regions = ref([]);
    const localisations = ref([]);
    const idApplication = ref(null);
    const showModal = ref(false);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const loadRegions = async () => {
        const data = await http.getRegions();
        regions.value = data;
    };

    const getDepartement = async () => {
        for (const regionCode of selected.value) {
            const data = await http.getLocalisationByNoRegion(regionCode);
            data.forEach(dept => {
                if (!localisations.value.find(existingDept => existingDept.noDept === dept.noDept)) {
                    localisations.value.push(dept);
                }
            });
        }
    };

    const getLocalisationByApp = (id) => {
        http.getLocalisationByApp(id).then((data) =>{
            localisations.value = data;
            idApplication.value = id;
        })
    };

    const insertLocalisationsFromApp = async () => {
        if (idApplication.value !== null) {
            await http.insertLocalisationsFromApp(idApplication.value, localisations.value.map(dept => dept.noDept));
            emit('close');
            emit('notification',{
                showNotification : true,
                message : 'Localisations ajoutée(s) à l\'application avec succès',
                type : "success",
            })
        } else{
            emit('notification',{
                showNotification : true,
                message : "Veuillez sélectionner une application",
                type : "info",
            })
        }
    };

    const removeDepartement = (departementValue) => {
        localisations.value = localisations.value.filter(dept => dept.noDept !== departementValue);
    };

    onMounted(() => {
        loadRegions();
        eventBus.on('updateApplicationSelected', (newIdApplication) => {
            idApplication.value = newIdApplication;
            getLocalisationByApp(newIdApplication);
        });

        onBeforeUnmount(() => {
            eventBus.off('updateApplicationSelected');
        });
    });

    return {
        selected,
        regions,
        localisations,
        idApplication,
        showModal,
        loadRegions,
        getDepartement,
        insertLocalisationsFromApp,
        removeDepartement,
        message,
        showNotification,
        type,
        duration
    };
}