import http from "@/http";
import eventBus from '@/eventBus.js';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useModifApplication(props,{emit}) {
    // Variables ref
    const titre = ref('');
    const annee = ref('');
    const detailMethode = ref(false);
    const noEtude = ref('');
    const finalites = ref([]);
    const finalite = ref({ code: '' });
    const resolutions = ref([]);
    const resolution = ref({ code: '' });
    const etendues = ref([]);
    const etendue = ref({ code: '' });
    const surface = ref('');
    const validee = ref(false);
    const errors = ref([]);
    const localId = ref(props.id);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        titre: yup.string().required('Ce champ est obligatoire'),
        annee: yup.string().required('Ce champ est obligatoire')
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const updateModalData = (idApplication) => {
        http.loadApplication(idApplication).then((data) => {
            titre.value = data.titre;
            annee.value = data.annee;
            detailMethode.value = data.detailMethode;
            noEtude.value = data.noEtude;
            finalite.value = data.finalite || { code: '' };
            resolution.value = data.resolution || { code: '' };
            etendue.value = data.etendue || { code: '' };
            surface.value = data.surface;
            validee.value = data.validee;
        });
    };

    // Chargement des données initiales
    onMounted(() => {
        eventBus.on('updateApplicationSelected', updateModalData);

        http.getEtendues().then((data) => {
            etendues.value = data;
        });
        http.getFinalites().then((data) => {
            finalites.value = data;
        });
        http.getResolutions().then((data) => {
            resolutions.value = data;
        });

        if (props.id !== undefined) {
            http.loadApplication(props.id).then((data) => {
                titre.value = data.titre;
                annee.value = data.annee;
                detailMethode.value = data.detailMethode;
                noEtude.value = data.noEtude;
                if (data.finalite !== null && data.finalite !== undefined) {
                    finalite.value = data.finalite;
                }
                if (data.resolution !== null && data.resolution !== undefined) {
                    resolution.value = data.resolution;
                }
                if (data.etendue !== null && data.etendue !== undefined) {
                    etendue.value = data.etendue;
                }
                surface.value = data.surface;
                validee.value = data.validee;
            });
        }
    });

    // Nettoyage à la destruction du composant
    onBeforeUnmount(() => {
        eventBus.off('modifierApplication', emitModifierApplication);
    });

    // Méthode pour émettre l'événement de modification de l'application
    const emitModifierApplication = handleSubmit(async (values) => {
        try {
            const applicationData = {
                idApplication: localId.value,
                titre: values.titre,
                annee: values.annee,
                detailMethode: detailMethode.value,
                noEtude: noEtude.value,
                finalite: finalite.value,
                resolution: resolution.value,
                etendue: etendue.value,
                surface: surface.value,
                validee: validee.value
            };
            const data = await http.updateApplication(applicationData);
            localId.value = data;
            emit('close');
            eventBus.emit('reloadApplications');
            emit('notification',{
                showNotification : true,
                message : "Application modifiée avec succès!",
                type : "success",
            })
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de l\'application.',
                type : "danger",
            })
        }
    });

    return {
        titre,
        annee,
        detailMethode,
        noEtude,
        finalites,
        finalite,
        resolutions,
        resolution,
        etendues,
        etendue,
        surface,
        validee,
        errors,
        emitModifierApplication,
        message,
        showNotification,
        type,
        duration
    };
}