<template>
  <div class="applicationList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div>
      <p class="idapp" v-if="selectedApplicationId != null">ID app: <strong>{{ selectedApplicationId }}</strong></p>
    </div>
    <div class="tableElement">
      <table class="table table-bordered table-striped table-hover table-sm" id="tableApp">
        <thead>
        <tr>
          <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="application in paginatedApplications" :key="application.idApplication" @click="selectApplication(application.idApplication)"
            :class="{ 'table-active': application.idApplication === selectedApplicationId }">
          <td class="text-center">
            <input type="checkbox" :checked="application.idApplication === selectedApplicationId" disabled>
          </td>
          <td class="text-center">{{ application.idApplication }}</td>
          <td class="text-center">{{ application.titre }}</td>
          <td class="text-center">{{ application.annee }}</td>
          <td class="text-center">
            <input type="checkbox" :checked="application.detailMethode" disabled>
          </td>
          <td class="text-center">
            <input type="checkbox" :checked="application.validee" disabled>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <b-row class="mt-4">
      <b-col cols="1" class="icon">
        <a class="icon" title="ajouter une nouvelle application" @click.prevent="showModalAdd = !showModalAdd; showModalAfficher=false;showModalModif=false" align-self="center">
          <i class="fas fa-plus-circle" id="ajoutApp"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedApplicationId !== null" title="voir l'application sélectionnée" @click.prevent="showModalAfficher = !showModalAfficher; showModalModif=false; showModalAdd=false" align-self="center">
          <i class="fa-solid fa-eye" id="showApp"></i>
        </a>
        <a class="icon" v-else title="voir l'application sélectionnée" @click="selection" align-self="center">
          <i class="fa-solid fa-eye" id="showApp"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedApplicationId !== null" title="modifier l'application sélectionnée" @click.prevent="showModalModif = !showModalModif;showModalAdd=false;showModalAfficher=false" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifApp"></i>
        </a>
        <a class="icon" v-else title="modifier l'application sélectionnée" @click="selection" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifApp"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a v-if="selectedApplicationId !== null" class="icon" title="supprimer l'application sélectionnée" @click="deleteApp" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteApp"></i>
        </a>
        <a v-else class="icon" title="supprimer l'application sélectionnée" @click="selection" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteApp"></i>
        </a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-app" v-model="filtre_applications" type="search" placeholder="Rechercher Application"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_applications" @click="filtre_applications = ''">
                <i class="fa-solid fa-arrows-rotate"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{ nbResult }} application(s) sur {{ totalRows }}</p>
        </div>
        <div class="pagination">
          <b-pagination
              class="customPagination"
              v-model="currentPage"
              :total-rows="nbResult !== 0 ? nbResult : filteredApplications.length"
              :per-page="perPage"
              aria-controls="my-card"
              size="sm"
              align="right"
              id="paginationApp"
          ></b-pagination>
        </div>
      </b-col>
    </b-row>
    <div id="afficher" v-if="showModalAfficher">
      <form>
        <modalAfficher :id="selectedApplicationId" :lectureSeule="lectureSeule" v-if="showModalAfficher" @close="showModalAfficher = false" class="center"></modalAfficher>
      </form>
    </div>

    <div id="ajout" v-if="showModalAdd" size="xl">
      <modalAdd @close="showModalAdd = false" @notification="handleNotification"></modalAdd>
    </div>

    <div id="modif" v-if="showModalModif" size="xl">
      <modalModif @close="showModalModif = false" :id="selectedApplicationId" @notification="handleNotification"></modalModif>
    </div>

  </div>
</template>

<script>

import modalAfficher from "@/components/Applications/Application/ModalAfficherApplication.vue"
import modalAdd from "@/components/Applications/Application/ModalAjouterApplication.vue"
import modalModif from "@/components/Applications/Application/ModalModifierApplication.vue"
import useApplicationList from "@/composition/Applications/applicationList";
import NotificationMessage from "@/components/Notification.vue"
import {ref} from "vue";
import eventBus from "@/eventBus";

export default {
  name: "ApplicationsList",
  components: {
    modalAdd,
    modalAfficher,
    modalModif,
    NotificationMessage,
  },
  props: {
    applications: {
      type: Array,
      required: true,
    },
    lectureSeule: {
      type: Boolean,
      default: true,
    },
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(props) {
    const applicationList = useApplicationList(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner une application";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...applicationList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification
    };
  },
};
</script>

<style scoped>
.idapp {
  text-align: end;
}

</style>