import { ref, computed, watch, watchEffect, onMounted, onBeforeUnmount } from 'vue';
import eventBus from '@/eventBus';
import http from '@/http';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAuteursList(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectedAuteurs = ref([]);
    const showModalAdd = ref(false);
    const showModalModif = ref(false);
    const selectedAuteurId = ref(null);
    const filtre_auteurs = ref("");
    const nbResult = ref(0);
    const auteursApp = ref([]);
    const idApplication = ref(null);

    const fields = [
        { key: "selection", label: "Sélection", class: "text-center" },
        { key: "idAuteur", label: "Id", class: "text-center" },
        { key: "nom", label: "Nom", class: "text-center" },
        { key: "prenom", label: "Prénom", class: "text-center" },
        { key: "email", label: "Mail", class: "text-center" },
        { key: "organisme", label: "Organisme", class: "text-center" },
    ];

    const handleDeleteAuteur = (auteur) => {
        auteursApp.value = auteursApp.value.filter(a => a.idAuteur !== auteur.idAuteur);
        clearSelected();
    };


    const getAuteursApp = (id) => {
        http.getAuteursFromApp(id).then((data) => {
            auteursApp.value = data;
        });
    };

    const lastSelectedAuteurId = computed(() => {
        if (selectedAuteurs.value.length > 0) {
            return selectedAuteurs.value[selectedAuteurs.value.length - 1];
        }
        return null;
    });

    const toggleSelected = (auteur) => {
        const index = selectedAuteurs.value.indexOf(auteur.idAuteur);
        if (index === -1) {
            selectedAuteurs.value.push(auteur.idAuteur);
            if (!auteursApp.value.some(a => a.idAuteur === auteur.idAuteur)) {
                auteursApp.value.push(auteur);
            }
        } else {
            selectedAuteurs.value.splice(index, 1);
            auteursApp.value = auteursApp.value.filter(a => a.idAuteur !== auteur.idAuteur);
        }
        selectedAuteurId.value = auteur.idAuteur;
    };

    const isSelected = (auteurId) => {
        return selectedAuteurs.value.includes(auteurId);
    };

    const insertAuteursFromApp = (auteursApp) => {
        if (idApplication.value !== undefined && idApplication.value !== null) {
            http.deleteAuteurFromApp(idApplication.value).then(() => {
                return Promise.all(auteursApp.map(auteur => http.insertAuteursFromApp(idApplication.value, auteur.idAuteur)));
            }).then(() => {
                clearSelected();
                eventBus.emit("notification", {
                    message: "Auteur(s) ajouté(s) à l'application avec succès",
                    type: "success"
                });
            }).catch(() => {
                eventBus.emit("notification", {
                    message: "Erreur lors de l'ajout des auteurs.",
                    type: "danger"
                });
            });
        }else{
            eventBus.emit("notification", {
                message: "Veuillez selectionner une application",
                type: "info"
            });
        }
    };

    const clearTable = () => {
        auteursApp.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedAuteurs.value = [];
    };

    const filteredAuteurs = computed(() => {
        if (!filtre_auteurs.value) return props.auteurs;

        return props.auteurs.filter(
            (auteur) =>
                (auteur.nom &&
                    auteur.nom.toLowerCase().includes(filtre_auteurs.value.toLowerCase())) ||
                (auteur.prenom &&
                    auteur.prenom.toLowerCase().includes(filtre_auteurs.value.toLowerCase())) ||
                (auteur.email &&
                    auteur.email.toLowerCase().includes(filtre_auteurs.value.toLowerCase())) ||
                (auteur.organisme &&
                    auteur.organisme.toLowerCase().includes(filtre_auteurs.value.toLowerCase()))
        );
    });

    watch(lastSelectedAuteurId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateAuteurSelected", newVal);
        }
    });

    watchEffect(() => {
        nbResult.value = filteredAuteurs.value.length;
        currentPage.value = 1;
    });

    const paginatedAuteurs = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredAuteurs.value.slice(start, end);
    });

    const deleteAuteur = async () => {
        if (selectedAuteurId.value !== null) {
            if (confirm(`Voulez-vous vraiment supprimer l'auteur : ${selectedAuteurId.value}`)) {
                try{
                    await http.deleteAuteur(selectedAuteurId.value);
                    eventBus.emit("notification", {
                        message: "L'auteur a été supprimé avec succès",
                        type: "success"
                    });
                    auteursApp.value = auteursApp.value.filter(a => a.idAuteur !== selectedAuteurId.value);
                    selectedAuteurId.value = null;
                    eventBus.emit("reloadAuteurs");
                } catch (error) {
                    eventBus.emit("notification", {
                        message: "Erreur lors de la suppression de l'auteur",
                        type: "danger"
                    });
                }
            }
        }
    };

    const updateAuteurs = () => {
        if (!filtre_auteurs.value) {
            nbResult.value = props.auteurs.length;
            return;
        }
        nbResult.value = filteredAuteurs.value.length;
    };

    onMounted(() => {
        eventBus.on("updateApplicationSelected", (id) => {
            getAuteursApp(id);
            idApplication.value = id;
        });

        eventBus.on("auteurModifie", (auteurModifie) => {
            const index = auteursApp.value.findIndex(a => a.idAuteur === auteurModifie.idAuteur);
            if (index !== -1) {
                auteursApp.value[index] = auteurModifie;
            } else {
                auteursApp.value.push(auteurModifie);
            }
        });
    });

    onBeforeUnmount(() => {
        eventBus.off("updateApplicationSelected");
        eventBus.off("auteurModifie");
    });

    return {
        perPage,
        currentPage,
        showModalAdd,
        showModalModif,
        selectedAuteurId,
        selectedAuteurs,
        filtre_auteurs,
        nbResult,
        fields,
        filteredAuteurs,
        paginatedAuteurs,
        totalRows: computed(() => props.auteurs.length),
        deleteAuteur,
        updateAuteurs,
        lastSelectedAuteurId,
        auteursApp,
        idApplication,
        toggleSelected,
        isSelected,
        handleDeleteAuteur,
        insertAuteursFromApp,
        clearTable,
        clearSelected,
    };
}
