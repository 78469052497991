<template>
  <div class="organismeList">
    <div class="tableSecondElement">
      <table class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="organisme in organismesApp" :key="organisme.idOrganisme">
            <td class="text-center">{{ organisme.idOrganisme }}</td>
            <td class="text-center">{{ organisme.sigle }}</td>
            <td class="text-center">{{ organisme.nom }}</td>
            <td class="text-center">{{ organisme.noDept }}</td>
            <td class="text-center">{{ organisme.ville }}</td>
            <td class="text-center"><a @click="supprimerOrganisme(organisme)" class="icon_delete" title="supprimer l'organisme"><i class="fa-solid fa-trash-can"></i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, toRefs } from 'vue';

export default defineComponent({
  name: "OrganismeAppList",

  props:{
    organismesApp:{
      type:Array,
      required:true
    },

    lectureSeule:{
      type:Boolean,
      default:true
    }
  },

  setup(props, { emit }) {
    const fields = ref([
      {key: 'idOrganisme', label:'Id', class: 'text-center'},
      {key: 'sigle', label:'Sigle', class: 'text-center'},
      {key: 'nom', label:'Nom', class: 'text-center'},
      {key: 'noDept', label:'Département', class: 'text-center'},
      {key: 'ville', label:'Ville', class: 'text-center'},
      { key: "actions", label: "Actions", class: "text-center" }
    ]);

    const supprimerOrganisme = (organisme) => {
      emit("deleteOrganisme", organisme);
    };

    return {
      ...toRefs(props),
      fields,
      supprimerOrganisme
    };
  }
});

</script>
<style scoped>

</style>