<template>
  <transition name="modal">
    <b-card class="modal-container">
      <form @submit.prevent="emitInsertOrganisme" id="validOrg">
        <div class="titreModal pt-2 pb-2">
          <h5>Ajouter un Organisme</h5>
        </div>
        <div>
          <b-card class=" formModal mr-4 ml-4">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Type*:" label-for="input-horizontal">
              <Field as="select" name="noTypeOrg" class="form-control">
                <option v-for="n in noTypeOrgs" :value="n.code" :key="n.code">{{n.signification}}</option>
              </Field>
              <ErrorMessage name="noTypeOrg" class="text-danger" />
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Sigle*:" label-for="input-2">
                  <Field name="sigle" type="text" class="form-control" />
                  <ErrorMessage name="sigle" class="text-danger" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-3" label-size="sm" label="Nom:" label-for="input-3">
              <b-form-input id="input-3" v-model="nom" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Service:" label-for="input-4">
              <b-form-input id="input-4" v-model="service" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Département*:" label-for="input-5">
              <Field as="select" name="localisation" class="form-control">
                <option v-for="l in localisations" :value="l.noDept" :key="l.noDept">{{l.nomDept}}</option>
              </Field>
              <ErrorMessage name="localisation" class="text-danger" />
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-6" label-size="sm" label="Ville*:" label-for="input-6">
              <Field name="ville" type="text" class="form-control" />
              <ErrorMessage name="ville" class="text-danger" />
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-7" label-size="sm" label="Adresse:" label-for="input-7">
              <b-form-input id="input-7" v-model="adresse" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-8" label-size="sm" label="N° tél:" label-for="input-8">
                  <b-form-input id="input-8" v-model="tel" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-9" label-size="sm" label="N° fax:" label-for="input-9">
                  <b-form-input id="input-9" v-model="fax" type="text" size="sm"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group class="labelTitre" id="input-group-10" label-size="sm" label="Adresse Web:" label-for="input-10">
              <b-form-input id="input-10" v-model="web" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-11" label-size="sm" label="Email:" label-for="input-11">
              <Field name="email" type="email" placeholder="Email" class="form-control" />
              <ErrorMessage name="email" class="text-danger" />
            </b-form-group>
          </b-card>
        </div>
        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
        </div>
      </form>

    </b-card>
  </transition>
</template>

<script>
import {defineComponent} from 'vue';
import { Field, ErrorMessage } from 'vee-validate';
import useAjoutOrganisme from '@/composition/Organismes/ajoutOrganisme';

export default defineComponent({
  name:"ModalAddOrganisme",
  components: {
    Field,
    ErrorMessage
  },
  setup(_, { emit }) {
    return useAjoutOrganisme({emit});
  },
});
</script>

<style scoped>

</style>