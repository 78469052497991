<template>
  <transition name="modal">
    <b-card class="modal-container">
      <p class="indication"> Veuillez tout d'abord sélectionner la (ou les) région(s) et ensuite le ou les département(s) sur le(s)quel(s) l'application a lieu 	(en supprimant les départements inutiles).</p>
      <div>
        <b-card class="formAfficher mr-4 ml-4">
          <form class="afficher" id="afficherLoc">
            <b-row>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="Région(s):" label-for="input-4">
                  <b-form-select id="input-4" v-model="selected" multiple :select-size="8">
                    <option v-for="r in regions" v-bind:value="r.code" v-bind:key="r.code">{{r.signification}}</option>
                  </b-form-select>
                </b-form-group>
                <b-button pill class="select" v-on:click = "getDepartement" align-self="center" id="selectRegion">Ajouter</b-button>
              </b-col>
              <b-col cols="6">
                <b-form-group class="labelTitre" id="input-group-4" label-size="sm" label="	Département(s):" label-for="input-4">
                  <ul>
                    <li v-for="l in localisations" :key="l.noDept" class="list-item">
                      {{ l.nomDept }}
                      <a @click="removeDepartement(l.noDept)" class="icon_delete" title="supprimer le département"><i class="fa-solid fa-trash-can"></i></a>
                    </li>
                  </ul>
                </b-form-group>
                <div class="bouton">
                  <b-button  pill class="save" @click="insertLocalisationsFromApp()" align-self="center" id="addLocToApp">Enregistrer la localisation</b-button>
                </div>
              </b-col>
            </b-row>
          </form>
        </b-card>
      </div>
    </b-card>
  </transition>
</template>
<script>
import useLocalisationList from "@/composition/Localisation/localisation"

export default {
  name: 'localisationList',

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(_, { emit }) {
    return useLocalisationList(emit);
  },
};
</script>

<style scoped>

.modal-container {
  border-radius: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.formAfficher{
  background-color: aliceblue;
}
.afficher{
  margin-top: -1em;
}
.indication{
  font-size: 15px;
}
.select{
  color: white !important;
  font-size: 15px;
  text-align: center;
  background-color: #008c8e !important;
  margin-top: 1em;
}
.select:hover{
  color: black !important;
}
.save{
  margin-top: 1em !important;
  color: white !important;
  font-size: 15px;
  text-align: right;
  background-color: #9dc544 !important;
}
.save:hover{
  color: black !important;
}

.list-item{
  list-style: none;
}

</style>