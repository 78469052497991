<template>
  <transition name="modal">
    <b-card class="modal-container">
      <form @submit.prevent="emitInsertAuteur" id="validAuteur">
        <div class="titreModal pt-2 pb-2">
          <h5>Ajouter un Auteur</h5>
        </div>

        <div>
          <b-card class=" formModal mr-4 ml-4">
            <b-form-group class="labelTitre" id="input-group-1" label-size="sm" label="Nom de l'auteur:" label-for="input-horizontal">
              <b-form-input id="input-1" v-model="nom" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-2" label-size="sm" label="Prénom de l'auteur:" label-for="input-2">
              <b-form-input id="input-2" v-model="prenom" type="text" size="sm">
              </b-form-input>
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Mail de l'auteur:" label-for="input-5">
              <Field name="email" type="email" placeholder="Email" class="form-control" />
              <ErrorMessage name="email" class="text-danger" />
            </b-form-group>
            <b-form-group class="labelTitre" id="input-group-5" label-size="sm" label="Organisme" label-for="input-6">
              <b-input-group>
                <b-form-input id="input-6" v-model="organismeSearch"
                              @input="updateSuggestions"
                              placeholder="Rechercher un organisme" type="text" size="sm">
                </b-form-input>
                <b-input-group-append>
                  <b-button @click="clearOrganismeSearch" variant="outline-danger" size="sm">Effacer</b-button>
                </b-input-group-append>
              </b-input-group>

              <div class="d-flex justify-content-center align-items-center h-100" v-if="organismeSearch">
                <div class="suggestions" v-if="suggestions.length">
                  <table class="table table-bordered table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="org in paginatedSuggestions" :key="org.id" @click="selectOrganisme(org)"
                          :class="{ 'table-active': isSelected(org) }">
                        <td class="text-center">{{ org.sigle }}</td>
                        <td class="text-center">{{ org.nom }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="suggestions.length"
                      :per-page="perPage">
                  </b-pagination>
                </div>
              </div>
            </b-form-group>
          </b-card>
        </div>

        <div class="bouton">
          <b-button pill type="submit" class="valid m-2">Valider</b-button>
          <b-button pill class="closed m-2" @click="$emit('close')">Annuler</b-button>
        </div>
      </form>
    </b-card>
  </transition>
</template>

<script>

import { Field, ErrorMessage} from 'vee-validate';
import useAjoutAuteur from "@/composition/Auteurs/ajoutAuteur";

export default {
  name: "ModalAddAuteur",
  components: {
    Field,
    ErrorMessage
  },

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(_, { emit }) {
    return useAjoutAuteur(emit);
  }
};
</script>

<style scoped>

.suggestions {
  position: relative;
  margin-top: 1em;
  width: 80%;
  background-color: #f8f9fa;
  font-size: 12px;
}
</style>