import { ref, watchEffect, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http.js';
import eventBus from '@/eventBus.js';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAjoutAuteur(emit) {
    const locale = ref("fr-FR");
    const idAuteur = ref("");
    const nom = ref("");
    const prenom = ref("");
    const email = ref("");
    const organismes = ref([]);
    const organismeSelectionne = ref(null);
    const organismeSearch = ref("");
    const suggestions = ref([]);
    const currentPage = ref(1);
    const perPage = ref(7);
    const paginatedSuggestions = ref([]);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        email: yup.string().email('Le format de l\'email saisi est incorrect')
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const fields = [
        { key: 'sigle', label: 'Sigle', class: 'text-center' },
        { key: 'nom', label: 'Nom', class: 'text-center' },
    ];

    const handleInsertAuteur = handleSubmit(async (values) => {
        const auteurData = {
            idAuteur: idAuteur.value,
            nom: nom.value,
            prenom: prenom.value,
            email: values.email,
            organisme: organismeSelectionne.value,
        };

        try {
            const data = await http.insertAuteur(auteurData);
            idAuteur.value = data;
            emit('close');
            eventBus.emit('reloadAuteurs');
            emit('notification',{
                showNotification : true,
                message : "Auteur ajouté avec succès!",
                type : "success",
            })
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de l\'ajout de l\'auteur.',
                type : "danger",
            })
        }
    });

    onMounted(() => {
        http.getOrganismesBySigle().then(data => {
            organismes.value = data;
        });
        eventBus.on('insertAuteur', handleInsertAuteur);
    });

    onBeforeUnmount(() => {
        eventBus.off('insertAuteur', handleInsertAuteur);
    });

    const updateSuggestions = () => {
        suggestions.value = organismes.value.filter(org =>
            org.sigle.toLowerCase().includes(organismeSearch.value.toLowerCase()) ||
            org.nom.toLowerCase().includes(organismeSearch.value.toLowerCase())
        );
    };

    watchEffect(() => {
        suggestions.value = organismes.value.filter(org =>
            org.sigle.toLowerCase().includes(organismeSearch.value.toLowerCase()) ||
            org.nom.toLowerCase().includes(organismeSearch.value.toLowerCase())
        );
        currentPage.value = 1;
    });

    watchEffect(() => {
        const startIndex = (currentPage.value - 1) * perPage.value;
        paginatedSuggestions.value = suggestions.value.slice(startIndex, startIndex + perPage.value);
    });

    const selectOrganisme = (org) => {
        organismeSelectionne.value = org;
        organismeSearch.value = `${org.sigle} (${org.nom})`;
        suggestions.value = [];
    };

    const clearOrganismeSearch = () => {
        organismeSearch.value = '';
        suggestions.value = [];
    };

    const emitInsertAuteur = () => {
        eventBus.emit("insertAuteur");
    };

    const isSelected = (org) => {
        return organismeSelectionne.value && organismeSelectionne.value.id === org.id;
    };

    return {
        locale,
        idAuteur,
        nom,
        prenom,
        email,
        organismes,
        organismeSearch,
        suggestions,
        currentPage,
        perPage,
        fields,
        updateSuggestions,
        selectOrganisme,
        clearOrganismeSearch,
        emitInsertAuteur,
        isSelected,
        paginatedSuggestions,
        message,
        showNotification,
        type,
        duration
    };
}