import { ref, computed, onMounted, onBeforeUnmount, watchEffect, watch } from "vue";
import http from "@/http";
import eventBus from "@/eventBus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useOrganismeList(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectedOrganismes = ref([]);
    const showModalAdd = ref(false);
    const showModalModif = ref(false);
    const selectedOrganismeId = ref(null);
    const filtre_organismes = ref("");
    const nbResult = ref(0);
    const organismesApp = ref([]);
    const idApplication = ref(null);
    const totalRows = computed(() => props.organismes.length);

    const fields = [
        { key: 'selection', label: 'Sélection', class: 'text-center' },
        { key: 'idOrganisme', label: 'Id', class: 'text-center' },
        { key: 'sigle', label: 'Sigle', class: 'text-center' },
        { key: 'nom', label: 'Nom', class: 'text-center' },
        { key: 'noDept', label: 'Département', class: 'text-center' },
        { key: 'ville', label: 'Ville', class: 'text-center' },
    ];

    const lastSelectedOrganismeId = computed(() => {
        if (selectedOrganismes.value.length > 0) {
            return selectedOrganismes.value[selectedOrganismes.value.length - 1];
        }
        return null;
    });

    const toggleSelected = (organisme) => {
        const index = selectedOrganismes.value.indexOf(organisme.idOrganisme);
        if (index === -1) {
            selectedOrganismes.value.push(organisme.idOrganisme);
            if (!organismesApp.value.some(o => o.idOrganisme === organisme.idOrganisme)) {
                organismesApp.value.push(organisme);
            }
        } else {
            selectedOrganismes.value.splice(index, 1);
            organismesApp.value = organismesApp.value.filter(o => o.idOrganisme !== organisme.idOrganisme);
        }
        selectedOrganismeId.value = organisme.idOrganisme;
    };

    const isSelected = (organismeId) => {
        return selectedOrganismes.value.includes(organismeId);
    };

    const clearTable = () => {
        organismesApp.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedOrganismes.value = [];
    };

    const handleDeleteOrganisme = (organisme) => {
        organismesApp.value = organismesApp.value.filter(o => o.idOrganisme !== organisme.idOrganisme);
        clearSelected();
    };

    const getOrganismesApp = (id) => {
        http.getOrganismesConcepteurFromApp(id).then((data) => {
            organismesApp.value = data;
        });
    };

    const insertOrgCFromApp = (organismesApp) => {
        if (idApplication.value !== undefined && idApplication.value !== null) {
            http.deleteOrganismesConcepteurFromApp(idApplication.value).then(() => {
                return Promise.all(organismesApp.map(organisme => http.insertOrganismesConcepteurFromApp(idApplication.value, organisme.idOrganisme)));
            }).then(() => {
                clearSelected();
                eventBus.emit("notification", {
                    message: "Organisme(s) ajouté(s) à l'application avec succès",
                    type: "success"
                });
            }).catch(() => {
                eventBus.emit("notification", {
                    message: "Erreur lors de l'ajout des organismes.",
                    type: "danger"
                });
            });
        }else{
            eventBus.emit("notification", {
                message: "Veuillez selectionner une application",
                type: "info"
            });
        }
    };

    const filteredOrganismes = computed(() => {
        if (!filtre_organismes.value) return props.organismes;

        return props.organismes.filter(
            (organisme) =>
                (organisme.sigle &&
                    organisme.sigle.toLowerCase().includes(filtre_organismes.value.toLowerCase())) ||
                (organisme.noDept &&
                    organisme.noDept.toLowerCase().includes(filtre_organismes.value.toLowerCase())) ||
                (organisme.nom &&
                    organisme.nom.toLowerCase().includes(filtre_organismes.value.toLowerCase())) ||
                (organisme.ville &&
                    organisme.ville.toLowerCase().includes(filtre_organismes.value.toLowerCase()))
        );
    });

    const deleteOrganisme = async () => {
        if (confirm(`Voulez-vous vraiment supprimer l'organisme : ${selectedOrganismeId.value}`)) {
            try{
                await http.deleteOrganisme(selectedOrganismeId.value);
                eventBus.emit("notification", {
                    message: "L'organisme a été supprimé avec succès",
                    type: "success"
                });
                organismesApp.value = organismesApp.value.filter(o => o.idOrganisme !== selectedOrganismeId.value);
                selectedOrganismeId.value = null;
                eventBus.emit("reloadOrganismes");
            } catch (error) {
                eventBus.emit("notification", {
                    message: "Erreur lors de la suppression de l'organisme",
                    type: "danger"
                });
            }
        }
    };

    const paginatedOrganismes = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredOrganismes.value.slice(start, end);
    });

    watch(lastSelectedOrganismeId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateOrganismeSelected", newVal);
        }
    });

    watchEffect(() => {
        nbResult.value = filteredOrganismes.value.length;
        currentPage.value = 1;
    });

    onMounted(() => {
        eventBus.on("updateApplicationSelected", (id) => {
            getOrganismesApp(id);
            idApplication.value = id;
        });
        eventBus.on("organismeModifie", (organismeModifie) => {
            const index = organismesApp.value.findIndex(o => o.idOrganisme === organismeModifie.idOrganisme);
            if (index !== -1) {
                organismesApp.value[index] = organismeModifie;
            } else {
                organismesApp.value.push(organismeModifie);
            }
        });
    });

    onBeforeUnmount(() => {
        eventBus.off("updateApplicationSelected");
        eventBus.off("organismeModifie");
    });

    return {
        perPage,
        currentPage,
        showModalAdd,
        showModalModif,
        selectedOrganismeId,
        selectedOrganismes,
        filtre_organismes,
        nbResult,
        fields,
        filteredOrganismes,
        paginatedOrganismes,
        deleteOrganisme,
        lastSelectedOrganismeId,
        organismesApp,
        idApplication,
        toggleSelected,
        isSelected,
        handleDeleteOrganisme,
        insertOrgCFromApp,
        clearTable,
        clearSelected,
        totalRows,
    };
}