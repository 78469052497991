import http from "@/http";
import eventBus from '@/eventBus.js';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useModifOrganisme(props,{emit}) {

    const nom = ref('');
    const sigle = ref('');
    const service = ref('');
    const adresse = ref('');
    const ville = ref('');
    const tel = ref('');
    const fax = ref('');
    const email = ref('');
    const web = ref('');
    const noTypeOrg = ref({ code: '' });
    const noTypeOrgs = ref([]);
    const localisation = ref({});
    const localisations = ref([]);
    const localId = ref(props.id);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        email: yup.string().nullable().notRequired().email('Le format de l\'email saisi est incorrect'),
        noTypeOrg: yup.string().required('Ce champ est obligatoire'),
        sigle: yup.string().required('Ce champ est obligatoire'),
        localisation: yup.string().required('Ce champ est obligatoire'),
        ville: yup.string().required('Ce champ est obligatoire')
    });

    const {handleSubmit, setFieldValue} = useForm({
        validationSchema: schema
    });

    const updateModalData = (idOrganisme) => {
        http.loadOrganisme(idOrganisme).then((data) => {
            noTypeOrg.value = data.noTypeOrg || { code: '' };
            sigle.value = data.sigle;
            nom.value = data.nom;
            adresse.value = data.adresse;
            service.value = data.service;
            localisation.value = data.localisation;
            ville.value = data.ville;
            tel.value = data.tel;
            fax.value = data.fax;
            email.value = data.email;
            web.value = data.web;
        });
    };

    onMounted(() => {
        eventBus.on('updateOrganismeSelected', updateModalData);

        http.getTypeOrgs().then((data)=> {
            noTypeOrgs.value = data;
        });
        http.getLocalisations().then((data)=> {
            localisations.value = data;
        });
        if(props.id !== undefined){
            http.loadOrganisme(props.id).then((data) => {
                noTypeOrg.value = data.noTypeOrg || { code: '' };
                sigle.value = data.sigle;
                nom.value = data.nom;
                adresse.value = data.adresse;
                service.value = data.service;
                localisation.value = data.localisation;
                ville.value = data.ville;
                tel.value = data.tel;
                fax.value = data.fax;
                email.value = data.email;
                web.value = data.web;
            });
        }
    });

    onBeforeUnmount(() => {
        eventBus.off('updateOrganismeSelected', emitModifierOrganisme);
    });

    const emitModifierOrganisme = handleSubmit(async (values) => {
        try{
            const organismeData = {
                idOrganisme: localId.value,
                noTypeOrg: values.noTypeOrg,
                sigle: values.sigle,
                nom: nom.value,
                service: service.value,
                adresse: adresse.value,
                localisation: values.localisation,
                ville: values.ville,
                tel: tel.value,
                fax: fax.value,
                email: values.email,
                web: web.value
            };

            const data = await http.updateOrganisme(organismeData);
            localId.value = data;
            emit('close');
            eventBus.emit('reloadOrganismes');
            emit('notification',{
                showNotification : true,
                message : "Organisme modifié avec succès!",
                type : "success",
            });
            eventBus.emit('organismeModifie', organismeData);
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de l\'organisme.',
                type : "danger",
            })
        }
    });

    return {
        nom,
        sigle,
        service,
        adresse,
        ville,
        tel,
        fax,
        web,
        email,
        noTypeOrg,
        noTypeOrgs,
        localisation,
        localisations,
        emitModifierOrganisme,
        setFieldValue,
        message,
        showNotification,
        type,
        duration
    };
}