import { ref, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http'; // Assurez-vous que le chemin vers votre module http est correct
import eventBus from '@/eventBus'; // Assurez-vous que le chemin vers votre eventBus est correct
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useAjoutOrganisme({ emit }) {
    // Références réactives pour les données et les états
    const locale = ref("fr-FR");
    const idOrganisme = ref("");
    const nom = ref("");
    const sigle = ref("");
    const service = ref("");
    const adresse = ref("");
    const ville = ref("");
    const tel = ref("");
    const fax = ref("");
    const email = ref("");
    const web = ref("");
    const noTypeOrgs = ref([]);
    const localisations = ref([]);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    // Schéma de validation avec yup
    const schema = yup.object({
        email: yup.string().email('Le format de l\'email saisi est incorrect'),
        noTypeOrg: yup.string().required('Ce champ est obligatoire'),
        sigle: yup.string().required('Ce champ est obligatoire'),
        localisation: yup.string().required('Ce champ est obligatoire'),
        ville: yup.string().required('Ce champ est obligatoire')
    });

    // Utilisation de vee-validate pour la validation du formulaire
    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    // Chargement des données nécessaires lors du montage du composant
    onMounted(() => {
        http.getTypeOrgs().then(data => {
            noTypeOrgs.value = data;
        });
        http.getLocalisations().then(data => {
            localisations.value = data;
        });

        // Fonction pour gérer l'ajout d'un organisme
        const handleInsertOrganisme = handleSubmit(async (values) => {
            const organismeData = {
                idOrganisme: idOrganisme.value,
                noTypeOrg: values.noTypeOrg,
                sigle: values.sigle,
                nom: nom.value,
                service: service.value,
                adresse: adresse.value,
                localisation: values.localisation,
                ville: values.ville,
                tel: tel.value,
                fax: fax.value,
                email: values.email,
                web: web.value
            };

            try {
                const data = await http.insertOrganisme(organismeData);
                idOrganisme.value = data;
                emit('close');
                eventBus.emit('reloadOrganismes');
                emit('notification',{
                    showNotification : true,
                    message : "Organisme ajouté avec succès!",
                    type : "success",
                })
            } catch (error) {
                emit('notification',{
                    showNotification : true,
                    message : 'Erreur lors de l\'ajout de l\'organisme.',
                    type : "danger",
                })
            }
        });

        eventBus.on('insertOrganisme', handleInsertOrganisme);

        onBeforeUnmount(() => {
            eventBus.off('insertOrganisme', handleInsertOrganisme);
        });
    });

    const emitInsertOrganisme = () => {
        eventBus.emit('insertOrganisme');
    };

    return {
        locale,
        idOrganisme,
        nom,
        sigle,
        service,
        adresse,
        ville,
        tel,
        fax,
        email,
        web,
        noTypeOrgs,
        localisations,
        emitInsertOrganisme,
        message,
        showNotification,
        type,
        duration
    };
}