<template>
  <div class="organismeList">
    <div>
      <NotificationMessage v-if="showNotification" :message="notificationMessage" :type="notificationType" :duration="5000"/>
    </div>
    <div class="tableElement">
      <table id="tableOrgC" class="table table-bordered table-striped table-hover table-sm">
        <thead>
          <tr>
            <th v-for="field in fields" :key="field.key" class="text-center align-middle">{{ field.label }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="organisme in paginatedOrganismes" :key="organisme.idOrganisme" @click="toggleSelected(organisme)"
              :class="{ 'table-active': isSelected(organisme.idOrganisme) }">
            <td class="text-center">
              <input type="checkbox" v-model="selectedOrganismes" :value="organisme.idOrganisme" disabled>
            </td>
            <td class="text-center">{{ organisme.idOrganisme }}</td>
            <td class="text-center">{{ organisme.sigle }}</td>
            <td class="text-center">{{ organisme.nom }}</td>
            <td class="text-center">{{ organisme.noDept }}</td>
            <td class="text-center">{{ organisme.ville }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-row>
      <b-col cols="1" class="icon">
        <a class="icon" title="ajouter un nouvel organisme" @click.prevent ="showModalAdd = !showModalAdd; showModalModif=false" align-self="center">
          <i class="fas fa-plus-circle" id="ajoutOrgC"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a class="icon" v-if="selectedOrganismes.length > 0" title="modifier l'organisme sélectionnée"
           @click.prevent ="showModalModif = !showModalModif;showModalAdd=false" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifOrgC"></i>
        </a>
        <a class="icon" v-else title="modifier l'organisme sélectionnée" @click="selection" align-self="center">
          <i class="fa-solid fa-pen-to-square" id="modifOrgC"></i>
        </a>
      </b-col>
      <b-col cols="1" class="icon">
        <a v-if="selectedOrganismeId !== null" class="icon" title="supprimer l'organisme sélectionnée" @click="deleteOrganisme" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteOrgC"></i>
        </a>
        <a v-else class="icon" title="supprimer l'organisme sélectionnée" @click="selection" align-self="center">
          <i class="fa-solid fa-trash-can" id="deleteOrgC"></i>
        </a>
      </b-col>
      <b-col cols="3" class="icon">
        <b-form-group label-for="filter-input" label-align-sm="right" label-size="sm">
          <b-input-group size="sm">
            <b-form-input id="filter-input-orgC" v-model="filtre_organismes" type="search" placeholder="Rechercher Organisme"></b-form-input>
            <b-input-group-append>
              <b-button :disabled="!filtre_organismes" @click="filtre_organismes = ''"><i class="fa-solid fa-arrows-rotate"></i></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <div class="pagination">
          <p>{{nbResult}} organisme(s) sur {{totalRows}}</p>
        </div>
        <div class="pagination">
          <b-pagination class="customPagination"
                        v-model="currentPage"
                        :total-rows="nbResult !== 0 ? nbResult : filteredOrganismes.length"
                        :per-page="perPage"
                        aria-controls="my-card"
                        size="sm"
                        align="right"
                        id="paginationOrgC">
          </b-pagination>
        </div>
      </b-col>
    </b-row>

    <div id="my-modal6" size="lg" v-if="showModalAdd">
      <ModalAdd @close="showModalAdd = false" @notification="handleNotification"></ModalAdd>
    </div>
    <div id="my-modal7" size="lg" v-if="showModalModif && lastSelectedOrganismeId !== null">
      <ModalModif :id="lastSelectedOrganismeId" @close="showModalModif = false" @notification="handleNotification"></ModalModif>
    </div>

    <div v-if="organismesApp.length!==0" class="organismesApp mt-3">
      <b-card class="card-list">
        <p style="color: white"><strong><u>Liste des Organismes concepteur de l'application séléctionnée:</u></strong></p>
        <OrganismesAppList
          :organismesApp="organismesApp"
           @deleteOrganisme = "handleDeleteOrganisme">
        </OrganismesAppList>
      </b-card>
      <div class="bouton">
        <b-button size="sm" type="submit" @click="insertOrgCFromApp(organismesApp)" class="btn-ajout" id="addOrgCToApp">Enregistrer la séléction</b-button>
        <b-button size="sm" class="btn-ajout" @click="clearTable">Réinitialiser</b-button>
      </div>
    </div>

  </div>
</template>
<script>
import {defineComponent, ref} from "vue";
import useOrganismeList from "@/composition/Organismes/listOrganismesConcepteur";
import ModalAdd from "@/components/Applications/Organisme/ModalAjouterOrganisme.vue"
import ModalModif from "@/components/Applications/Organisme/ModalModifierOrganisme.vue"
import OrganismesAppList from "@/components/Applications/Organisme/OrganismesAppList.vue";
import NotificationMessage from "@/components/Notification.vue";
import eventBus from "@/eventBus";

export default defineComponent({
  name: "OrganismesList",
  components: {
    NotificationMessage,
    ModalAdd,
    ModalModif,
    OrganismesAppList,
  },

  props:{
    organismes:{
      type:Array,
      required:true
    },
  },

  setup(props) {
    const organismesConcepteurList = useOrganismeList(props);
    const showNotification = ref(false);
    const notificationMessage = ref("");
    const notificationType = ref("info");

    const selection = () => {
      notificationMessage.value = "Veuillez sélectionner un organisme";
      notificationType.value = "info";
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };

    const handleNotification = ({ message, type }) => {
      notificationMessage.value = message;
      notificationType.value = type;
      showNotification.value = true;
      setTimeout(() => {
        showNotification.value = false;
      }, 5000);
    };
    eventBus.on("notification", handleNotification);

    return {
      ...organismesConcepteurList,
      showNotification,
      notificationMessage,
      notificationType,
      selection,
      handleNotification
    };
  },
});
</script>
<style scoped>

.organismesApp{
  text-align: center;
  justify-content: center;
}

</style>