import {ref, computed, watchEffect, watch, onMounted, onBeforeUnmount} from "vue";
import http from "@/http";
import eventBus from "@/eventBus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useThematique(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectedThematiques = ref([]);
    const selectedThematiqueId = ref(null);
    const filtre_thematiques = ref("");
    const nbResult = ref(0);
    const thematiquesApp = ref([]);
    const idApplication = ref(null);
    const totalRows = computed(() => props.thematiques.length);

    const fields = [
        { key: 'selection', label: 'Sélection', class: 'text-center' },
        { key: 'idThematique', label: 'Id', class: 'text-center' },
        { key: 'groupe', label: 'Groupe thématique', class: 'text-center' },
        { key: 'thematiquePrincipale', label: 'Thématique', class: 'text-center' },
        { key: 'sousThematique', label: 'Sous thématique', class: 'text-center' },
    ];

    const handleDeleteThematique = (thematique) => {
        thematiquesApp.value = thematiquesApp.value.filter(t => t.idThematique !== thematique.idThematique);
        clearSelected();
    };

    const getThematiquesApp = (id) => {
        http.getThematiquesFromApp(id).then((data) => {
            thematiquesApp.value = data;
        });
    };

    const lastSelectedThematiqueId = computed(() => {
        if (selectedThematiques.value.length > 0) {
            return selectedThematiques.value[selectedThematiques.value.length - 1];
        }
        return null;
    });

    const toggleSelected = (thematique) => {
        const index = selectedThematiques.value.indexOf(thematique.idThematique);
        if (index === -1) {
            selectedThematiques.value.push(thematique.idThematique);
            if (!thematiquesApp.value.some(t => t.idThematique === thematique.idThematique)) {
                thematiquesApp.value.push(thematique);
            }
        } else {
            selectedThematiques.value.splice(index, 1);
            thematiquesApp.value = thematiquesApp.value.filter(t => t.idThematique !== thematique.idThematique);
        }
        selectedThematiqueId.value = thematique.idThematique;
    };

    const isSelected = (thematiqueId) => {
        return selectedThematiques.value.includes(thematiqueId);
    };

    const insertThematiquesFromApp = (thematiquesApp) => {
        if (idApplication.value !== undefined && idApplication.value !== null) {
            http.deleteThematiqueFromApp(idApplication.value).then(() => {
                return Promise.all(thematiquesApp.map(thematique => http.insertThematiquesFromApp(idApplication.value, thematique.idThematique)));
            }).then(() => {
                clearSelected();
                eventBus.emit("notification", {
                    message: "Thématique(s) ajoutée(s) à l'application avec succès",
                    type: "success"
                });
            }).catch(() => {
                eventBus.emit("notification", {
                    message: "Erreur lors de l'ajout des thématiques.",
                    type: "danger"
                });
            });
        }else{
            eventBus.emit("notification", {
                message: "Veuillez selectionner une application",
                type: "info"
            });
        }
    };

    const clearTable = () => {
        thematiquesApp.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedThematiques.value = [];
    };

    const filteredThematiques = computed(() => {
        if (!filtre_thematiques.value) return props.thematiques;

        return props.thematiques.filter(
            (thematique) =>
                (thematique.groupe &&
                    thematique.groupe.toLowerCase().includes(filtre_thematiques.value.toLowerCase())) ||
                (thematique.thematiquePrincipale &&
                    thematique.thematiquePrincipale.toLowerCase().includes(filtre_thematiques.value.toLowerCase())) ||
                (thematique.sousThematique &&
                    thematique.sousThematique.toLowerCase().includes(filtre_thematiques.value.toLowerCase()))
        );
    });

    const paginatedThematiques = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredThematiques.value.slice(start, end);
    });

    const updateThematiques = () => {
        if (!filtre_thematiques.value) {
            nbResult.value = props.thematiques.length;
            return;
        }
        nbResult.value = filteredThematiques.value.length;
    };

    watchEffect(() => {
        nbResult.value = filteredThematiques.value.length;
        currentPage.value = 1;
    });

    watch(lastSelectedThematiqueId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateThematiqueSelected", newVal);
        }
    });

    onMounted(() => {
        eventBus.on("updateApplicationSelected", (id) => {
            getThematiquesApp(id);
            idApplication.value = id;
        });
    });

    onBeforeUnmount(() => {
        eventBus.off("updateApplicationSelected");
    });

    return {
        perPage,
        currentPage,
        selectedThematiqueId,
        selectedThematiques,
        filtre_thematiques,
        nbResult,
        fields,
        filteredThematiques,
        paginatedThematiques,
        thematiquesApp,
        idApplication,
        totalRows,
        handleDeleteThematique,
        getThematiquesApp,
        lastSelectedThematiqueId,
        toggleSelected,
        isSelected,
        insertThematiquesFromApp,
        clearTable,
        clearSelected,
        updateThematiques
    };
}