import { ref, computed, watchEffect, watch, onMounted, onBeforeUnmount } from "vue";
import http from "@/http";
import eventBus from "@/eventBus";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useRefBiblioList(props) {
    const perPage = ref(10);
    const currentPage = ref(1);
    const selectedRefBiblios = ref([]);
    const selectMode = ref("multi");
    const showModalAdd = ref(false);
    const showModalModif = ref(false);
    const selectedRefBiblioId = ref(null);
    const filtre_refBiblios = ref("");
    const nbResult = ref(0);
    const refsApp = ref([]);
    const idApplication = ref(null);
    const totalRows = computed(() => props.refsBiblios.length);

    const fields = [
        {key: 'selection', label:'Sélection', class: 'text-center'},
        {key: 'idRef', label:'Id', class: 'text-center'},
        {key: 'titre', label:'Titre', class: 'text-center'},
        {key: 'type', label:'Type réf', class: 'text-center'},
        {key: 'document', label:'Doc. source', class: 'text-center'},
        {key: 'annee', label:'Année publi.', class: 'text-center'}
    ];

    const handleDeleteRef = (reference) => {
        refsApp.value = refsApp.value.filter(r => r.idRef !== reference.idRef);
        clearSelected();
    };

    const getRefsApp = (id) => {
        http.getRefsFromApp(id).then((data) => {
            refsApp.value = data;
        });
    };

    const lastSelectedRefBiblioId = computed(() => {
        if (selectedRefBiblios.value.length > 0) {
            return selectedRefBiblios.value[selectedRefBiblios.value.length - 1];
        }
        return null;
    });

    const toggleSelected = (refBiblio) => {
        const index = selectedRefBiblios.value.indexOf(refBiblio.idRef);
        if (index === -1) {
            selectedRefBiblios.value.push(refBiblio.idRef);
            if (!refsApp.value.some(r => r.idRef === refBiblio.idRef)) {
                refsApp.value.push(refBiblio);
            }
        } else {
            selectedRefBiblios.value.splice(index, 1);
            refsApp.value = refsApp.value.filter(r => r.idRef !== refBiblio.idRef);
        }
        selectedRefBiblioId.value = refBiblio.idRef;
    };

    const isSelected = (refId) => {
        return selectedRefBiblios.value.includes(refId);
    };

    const insertRefBibliosFromApp = (refsApp) => {
        if (idApplication.value !== undefined && idApplication.value !== null) {
            http.deleteRefsFromApp(idApplication.value).then(() => {
                return Promise.all(refsApp.map(refBiblio => http.insertRefsFromApp(idApplication.value, refBiblio.idRef)));
            }).then(() => {
                clearSelected();
                eventBus.emit("notification", {
                    message: "Référence(s) ajoutée(s) à l'application avec succès",
                    type: "success"
                });
            }).catch(() => {
                eventBus.emit("notification", {
                    message: "Erreur lors de l'ajout des références.",
                    type: "danger"
                });
            });
        }else{
            eventBus.emit("notification", {
                message: "Veuillez selectionner une application",
                type: "info"
            });
        }
    };

    const clearTable = () => {
        refsApp.value = [];
        clearSelected();
    };

    const clearSelected = () => {
        selectedRefBiblios.value = [];
    };

    const filteredRefBiblios = computed(() => {
        if (!filtre_refBiblios.value) return props.refsBiblios;

        return props.refsBiblios.filter(
            (ref) =>
                (ref.titre &&
                    ref.titre.toLowerCase().includes(filtre_refBiblios.value.toLowerCase())) ||
                (ref.annee &&
                    ref.annee.toString().includes(filtre_refBiblios.value.toString())) ||
                (ref.document &&
                    ref.document.toLowerCase().includes(filtre_refBiblios.value.toLowerCase()))
        );
    });

    watch(lastSelectedRefBiblioId, (newVal) => {
        if (newVal !== null) {
            eventBus.emit("updateRefBiblioSelected", newVal);
        }
    });

    watchEffect(() => {
        nbResult.value = filteredRefBiblios.value.length;
        currentPage.value = 1;
    });

    const paginatedRefBiblios = computed(() => {
        const start = (currentPage.value - 1) * perPage.value;
        const end = start + perPage.value;
        return filteredRefBiblios.value.slice(start, end);
    });

    const deleteRef = async () => {
        if (selectedRefBiblioId.value !== null) {
            const exists = await http.verifLAppRefExist(selectedRefBiblioId.value);
            if (exists) {
                eventBus.emit("notification", {
                    message: `Impossible de supprimer la référence ${selectedRefBiblioId.value} car elle est associée à des applications.`,
                    type: "danger"
                });
            } else {
                if (confirm(`Voulez-vous vraiment supprimer la reference : ${selectedRefBiblioId.value}`)) {
                    try{
                        http.deleteRef(selectedRefBiblioId.value);
                        eventBus.emit("notification", {
                            message: "La référence a été supprimée avec succès",
                            type: "success"
                        });
                        refsApp.value = refsApp.value.filter(r => r.idRef !== selectedRefBiblioId.value);
                        selectedRefBiblioId.value = null;
                        eventBus.emit('reloadRefs');
                    } catch (error) {
                        eventBus.emit("notification", {
                            message: "Erreur lors de la suppression de la référence",
                            type: "danger"
                        });
                    }
                }
            }
        }
    };

    const updateRefs = () => {
        if (!filtre_refBiblios.value) {
            nbResult.value = props.refsBiblios.length;
            return;
        }
        nbResult.value = filteredRefBiblios.value.length;
    };

    onMounted(() => {
        eventBus.on("updateApplicationSelected", (id) => {
            getRefsApp(id);
            idApplication.value = id;
        });
        eventBus.on("refModifie", (refModifie) => {
            const index = refsApp.value.findIndex(r => r.idRef === refModifie.idRef);
            if (index !== -1) {
                refsApp.value[index] = refModifie;
            } else {
                refsApp.value.push(refModifie);
            }
        });
    });

    onBeforeUnmount(() => {
        eventBus.off("updateApplicationSelected");
        eventBus.off("refModifie");
    });

    return {
        perPage,
        currentPage,
        selectMode,
        showModalAdd,
        showModalModif,
        selectedRefBiblioId,
        selectedRefBiblios,
        filtre_refBiblios,
        nbResult,
        fields,
        filteredRefBiblios,
        paginatedRefBiblios,
        deleteRef,
        updateRefs,
        lastSelectedRefBiblioId,
        refsApp,
        idApplication,
        totalRows,
        toggleSelected,
        isSelected,
        handleDeleteRef,
        insertRefBibliosFromApp,
        clearTable,
        clearSelected
    };
}