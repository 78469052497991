import { ref, watchEffect, onMounted, onBeforeUnmount } from 'vue';
import http from '@/http.js';
import eventBus from '@/eventBus.js';
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useModifierAuteur(props, emit) {
    const locale = ref("fr-FR");
    const idAuteur = ref('');
    const nom = ref('');
    const prenom = ref('');
    const email = ref('');
    const organismes = ref([]);
    const organismeSelectionne = ref(null);
    const organismeSearch = ref('');
    const suggestions = ref([]);
    const currentPage = ref(1);
    const perPage = ref(7);
    const localId = ref(props.id);
    const paginatedSuggestions = ref([]);

    const message = ref("");
    const showNotification = ref(false);
    const type = ref("info");
    const duration = ref(5000);

    const schema = yup.object({
        email: yup.string().nullable().notRequired().email('Le format de l\'email saisi est incorrect')
    });

    const { handleSubmit } = useForm({
        validationSchema: schema
    });

    const fields = [
        { key: 'sigle', label: 'Sigle', class: 'text-center' },
        { key: 'nom', label: 'Nom', class: 'text-center' },
    ];

    const updateModalData = (idAuteur) => {
        http.loadAuteur(idAuteur).then((data) => {
            nom.value = data.nom;
            prenom.value = data.prenom;
            email.value = data.email;
            if (data.organisme !== null) {
                organismeSearch.value = `${data.organisme.sigle} (${data.organisme.nom})`;
                organismeSelectionne.value = data.organisme;
            }
        });
    }

    onMounted(() => {
        http.getOrganismesBySigle().then(data => {
            organismes.value = data;
        });

        eventBus.on('updateAuteurSelected', updateModalData);

        if (props.id !== undefined) {
            http.loadAuteur(props.id).then((data) => {
                idAuteur.value = data.idAuteur;
                nom.value = data.nom;
                prenom.value = data.prenom;
                email.value = data.email;
                if (data.organisme !== null) {
                    organismeSearch.value = `${data.organisme.sigle} (${data.organisme.nom})`;
                    organismeSelectionne.value = data.organisme;
                }
            });
        }

        onBeforeUnmount(() => {
            eventBus.off('modifierAuteur', emitModifierAuteur);
        });
    });

    const updateSuggestions = () => {
        suggestions.value = organismes.value.filter(org =>
            org.sigle.toLowerCase().includes(organismeSearch.value.toLowerCase()) ||
            org.nom.toLowerCase().includes(organismeSearch.value.toLowerCase())
        );
    }

    watchEffect(() => {
        suggestions.value = organismes.value.filter(org =>
            org.sigle.toLowerCase().includes(organismeSearch.value.toLowerCase()) ||
            org.nom.toLowerCase().includes(organismeSearch.value.toLowerCase())
        );
        currentPage.value = 1;
    });

    watchEffect(() => {
        const startIndex = (currentPage.value - 1) * perPage.value;
        paginatedSuggestions.value = suggestions.value.slice(startIndex, startIndex + perPage.value);
    });

    const selectOrganisme = (org) => {
        organismeSelectionne.value = org;
        organismeSearch.value = `${org.sigle} (${org.nom})`;
        suggestions.value = [];
    };

    const onRowClicked = (item) => {
        selectOrganisme(item);
    };

    const clearOrganismeSearch = () => {
        organismeSearch.value = '';
        suggestions.value = [];
    };

    const emitModifierAuteur = handleSubmit(async (values) => {
        try {
            const auteurData = {
                idAuteur: idAuteur.value,
                nom: nom.value,
                prenom: prenom.value,
                email: values.email,
                organisme: organismeSelectionne.value,
            };
            const data = await http.updateAuteur(auteurData);
            localId.value = data;
            emit('close');
            eventBus.emit('reloadAuteurs');
            emit('notification',{
                showNotification : true,
                message : "Auteur modifié avec succès!",
                type : "success",
            });
            eventBus.emit('auteurModifie', auteurData);
        } catch (error) {
            emit('notification',{
                showNotification : true,
                message : 'Erreur lors de la modification de l\'auteur.',
                type : "danger",
            })
        }
    });

    const isSelected = (org) => {
        return organismeSelectionne.value && organismeSelectionne.value.id === org.id;
    };

    return {
        locale,
        idAuteur,
        nom,
        prenom,
        email,
        organismes,
        organismeSelectionne,
        organismeSearch,
        suggestions,
        currentPage,
        perPage,
        fields,
        selectOrganisme,
        updateSuggestions,
        onRowClicked,
        clearOrganismeSearch,
        emitModifierAuteur,
        isSelected,
        paginatedSuggestions,
        message,
        showNotification,
        type,
        duration
    };
}